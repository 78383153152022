import React, { useEffect, useState } from "react";
import style from "./interior.module.css";

import Image from "../image";
import { data } from "../data";

const Interior = () => {
  useEffect(() => {
    filterAll();
  }, []);
  const filterAll = () => {
    const filteredData = data
      .filter((el) => el.category == "interior")
      .sort((a, b) => (a.project_date < b.project_date ? 1 : -1));
    setProjects(filteredData);
  };

  const [projects, setProjects] = useState([]);

  return (
    <div className={style.container}>
      <h1 className={style.title}>Интерьер</h1>
      <div className={style.projects}>
        {projects.map((e, index) => (
          <div key={index} className={style.project}>
            <Image
              key={e.id}
              id={e.id}
              src={`/images/${e.id}/1.jpg`}
              title={e.name}
              description={e.description}
            />
            <p className={style.description}>{e.description}</p>
            <div className={style.info}>
              <div>{e.project_date}</div>
              <span>|</span>
              <div>{e.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interior;
