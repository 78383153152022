import React, { useState, useEffect } from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import style from "./header.module.css";

const Header = () => {
  const [clicked, setClick] = useState(false);
  const [active, setActive] = useState(1);
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const click = (url, id) => {
    navigate(url);
    setActive(id);
    setClick(!clicked);
  };

  return (
    <div className={style.container}>
      <div className={style.navbar}>
        <img
          src={`/images/logo/sates_logo.jpg`}
          className={style.mainImg}
          onClick={() => {
            navigate("/");
          }}
        />
        <ul
          style={mobile && !clicked ? { display: "none" } : { display: "flex" }}
        >
          <li onClick={() => click("/", 1)}>Бидний тухай</li>
          <span>/</span>
          <li onClick={() => click("/construction", 2)}>Барилга</li>
          <span>/</span>
          <li onClick={() => click("/interior", 3)}>Интерьер</li>
          <span>/</span>
          <li onClick={() => click("/services", 4)}>Үйлчилгээ</li>
        </ul>
        <div className={style.hamburger} onClick={() => setClick(!clicked)}>
          {clicked ? (
            <CgClose className={style.icon} />
          ) : (
            <HiOutlineMenuAlt4 className={style.icon} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
