import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { data } from "../data";
import Image from "../image";
import style from "./construction.module.css";

const Projects = () => {
  useEffect(() => {
    prepareTypes();
    prepareYears();
    filterAll();
  }, []);
  const [types, setTypes] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedType, setSelectedType] = useState("Төрөл");
  const [selectedYear, setSelectedYear] = useState("Огноо");
  const [projects, setProjects] = useState([]);

  const filterAll = () => {
    setSelectedType("Төрөл");
    setSelectedYear("Огноо");
    const filteredData = data
      .filter((el) => el.category == "construction")
      .sort((a, b) => (a.project_date < b.project_date ? 1 : -1));
    setProjects(filteredData);
  };
  const filterSpecial = () => {
    setSelectedType("Төрөл");
    setSelectedYear("Огноо");
    const filteredData = data.filter(
      (el) => el.category == "construction" && el.special == "true"
    );
    setProjects(filteredData);
  };
  const filterTypes = (value) => {
    setSelectedYear("Огноо");
    setSelectedType(value);
    const index = types.indexOf(types.filter((el) => el.value == value)[0]);
    if (index == 0) {
      const filteredData = data.filter((el) => el.category == "construction");
      setProjects(filteredData);
    } else {
      const filteredData = data.filter(
        (el) => el.category == "construction" && el.image_type == value
      );
      setProjects(filteredData);
    }
  };
  const filterYears = (value) => {
    setSelectedType("Төрөл");
    setSelectedYear(value);
    const index = years.indexOf(years.filter((el) => el.value == value)[0]);
    if (index == 0) {
      const filteredData = data.filter((el) => el.category == "construction");
      setProjects(filteredData);
    } else {
      const filteredData = data.filter(
        (el) => el.category == "construction" && el.project_date == value
      );
      setProjects(filteredData);
    }
  };
  const prepareTypes = () => {
    const typesVal = data
      .filter((e) => e.category == "construction")
      .map((a) => a.image_type);
    const type = [...new Set(typesVal)];
    const list = [];
    type.forEach((el) => list.push({ value: el, label: el }));
    list.unshift({ value: "Төрөл", label: "Төрөл" });
    setTypes(list);
  };
  const prepareYears = () => {
    const typesVal = data
      .filter((e) => e.category == "construction")
      .map((a) => a.project_date);
    const type = [...new Set(typesVal)];
    type.sort().reverse();
    const list = [];
    type.forEach((el) => list.push({ value: el, label: el }));
    list.unshift({ value: "Огноо", label: "Огноо" });
    setYears(list);
  };

  return (
    <div className={style.container}>
      <h1 className={style.title}>Барилга</h1>
      <div className={style.filter}>
        <div onClick={() => filterAll()}>Бүгд</div>
        <span className={style.span}>|</span>
        <div onClick={() => filterSpecial()}>Онцлох</div>
        <span className={style.span}>|</span>
        <Select
          bordered={false}
          onClick={() => {}}
          value={selectedType}
          onChange={filterTypes}
          dropdownStyle={{
            width: "auto",
          }}
          size={"large"}
          options={types}
        />
        <Select
          bordered={false}
          value={selectedYear}
          onChange={filterYears}
          size={"large"}
          options={years}
          dropdownStyle={{
            width: "auto",
          }}
        />
      </div>
      <div className={style.projects}>
        {projects.map((e, index) => (
          <div className={style.project} key={index}>
            <Image
              key={e.id}
              id={e.id}
              src={`/images/${e.id}/1.jpg`}
              title={e.name}
              description={e.description}
            />
            <p className={style.description}>{e.description}</p>
            <div className={style.info}>
              <div>{e.project_date}</div>
              <span>|</span>
              <div>{e.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
