import React from "react";

import { services } from "../data";
import style from "./service.module.css";

const Services = () => {
  return (
    <div className={style.container}>
      <h1 className={style.title}>{services.title}</h1>
      <div className={style.description}>
        <p>{services.description}</p>
      </div>
      <div>
        {/* <div className={style.images} style={{ marginBottom: "3rem" }}>
          <div className={style.column2}>
            <img src={"/images/1/1.jpg"} />
            <h4>{services.subtitle1}</h4>
            <p>{services.description1}</p>
          </div>
          <div className={style.column2}>
            <img src={"/images/2/1.jpg"} />
            <h4>{services.subtitle2}</h4>
            <p>{services.description2}</p>
          </div>
          <div className={style.column2}>
            <img src={"/images/2/1.jpg"} />
            <h4>{services.subtitle3}</h4>
            <p>{services.description3}</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Services;
