import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Button, Input, Modal } from "antd";

import Header from "./components/header";
import About from "./components/about";
import Construction from "./components/construction";
import Services from "./components/service";
import Detail from "./components/detail";
import Footer from "./components/footer";
import Interior from "./components/interior";

function App() {
  useEffect(() => {
    showModal();
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };
    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);
    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<About />}></Route>
        <Route exact path="/construction" element={<Construction />}></Route>
        <Route exact path="/interior" element={<Interior />}></Route>
        <Route exact path="/services" element={<Services />}></Route>
        <Route exact path="/details/:id" element={<Detail />} />
      </Routes>
      <Footer />
      <Modal
        title="Нууц үгээ оруулна уу"
        open={isModalOpen}
        centered={true}
        maskClosable={false}
        maskStyle={{ backgroundColor: "black", opacity: 0.9 }}
        closeIcon={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            style={{ color: "white", backgroundColor: "black" }}
          >
            Нэвтрэх
          </Button>,
        ]}
      >
        <Input.Password placeholder="******" size="large" />
      </Modal>
    </BrowserRouter>
  );
}

export default App;
