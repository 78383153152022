import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";

// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#ffffff",
          fontFamily: "Oswald, sans-serif",
          borderRadius: 2,
          colorBgTextActive: "#ff0002",
          colorHighlight: "#ff0002",
          // fontSize: "16px",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </StrictMode>
);
