import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

const Image = ({ src, title, description, id, className }) => {
  const navigate = useNavigate();

  return (
    <div
      layout
      className={`effect ${className}`}
      key={id}
      onClick={() => navigate(`/details/${id}`)}
    >
      <img src={src} alt="" />
      <div className="caption">
        <div className="topSide"></div>
        <div className="bottomSide"></div>
        <span className="description">{description}</span>
        <div className="upperLine"></div>
        <span className="title">{title}</span>
      </div>
    </div>
  );
};

export default Image;
