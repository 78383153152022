import React from "react";
import { footer } from "../data";
import style from "./footer.module.css";

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.container}>
        <div className={style.info}>
          <div>{footer.address}</div>
          <span>|</span>
          <div className={style.phone}>{footer.phone}</div>
        </div>
        <div className={style.copyright}>{footer.copyright}</div>
      </div>
    </div>
  );
};

export default Footer;
