import React, { useEffect, useState } from "react";

import Image from "../image";
import { about } from "../data";
import style from "./about.module.css";
import { data } from "../data";

const About = () => {
  useEffect(() => {
    setSpecial(data.filter((el) => el.special == "true"));
  }, []);
  const [special, setSpecial] = useState(data);

  return (
    <div className={style.container}>
      <h1 className={style.title}>{about.title}</h1>
      <div className={style.images}>
        {special.map((e, index) => (
          <Image
            className={index == 0 ? style.column2 : ""}
            src={`/images/${e.id}/1.jpg`}
            title={e.name}
            description={e.description}
            id={e.id}
          />
        ))}
      </div>
      <div className={style.description}>
        <div className={style.desc1}>
          <p className={style.goal}>Эрхэм зорилго</p>
          <p>
            Бид эрхэм захиалагчидтай хамтран ирээдүй хойч үедээ архитектурын гоо
            зүй, орон зайн хэрэгцээг хангасан уран барилгуудыг өвлүүлнэ.
          </p>
        </div>
        <div className={style.desc2}>
          <p className={style.goal}>Алсын хараа</p>
          <p>
            Бид хот төлөвлөлтийн болон олон нийтийн барилгын орчин үеийн чиг
            хандлагыг тодорхойлно.
          </p>
        </div>
        <div className={style.desc3}>
          <p className={style.goal}>Үнэт зүйлс</p>
          <p>
            Мэргэжлийн өсөн хөгжих хамтрагч нар, архитектурын боловсролыг
            хүндэтгэдэг захиалагчид юм.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
