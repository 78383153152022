import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { data } from "../data";
import style from "./details.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Thumbs,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Detail = () => {
  const { id } = useParams();
  const [mainImg, setMainImg] = useState(1);
  const project = data[id - 1];
  const topRef = useRef(null);
  const images = [];
  for (let i = 0; i < project.images; i++) {
    images.push(i);
  }

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={style.container}>
      <h1 className={style.title} ref={topRef}>
        {project.name}
      </h1>
      <div className={style.images}>
        <Swiper
          className={style.mainImg}
          style={{
            "--swiper-pagination-color": "#000000",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "12px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
          }}
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {images.map((el) => {
            return (
              <SwiperSlide>
                {id == "35" && el == "4" ? (
                  <video
                    className={style.singleImg}
                    autoPlay
                    loop
                    muted
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  >
                    <source
                      src={`/images/${project.id}/${el + 1}.mp4`}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    className={style.singleImg}
                    src={`/images/${project.id}/${el + 1}.jpg`}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className={style.info}>
          <div className={style.details}>
            <div className={style.key}>Байршил</div>
            <div className={style.value}>{project.location}</div>
          </div>
          {project.category != "interior" ? (
            <>
              <div className={style.details}>
                <div className={style.key}>Зургийн төрөл</div>
                <div className={style.value}>{project.image_type}</div>
              </div>
              <div className={style.details}>
                <div className={style.key}>Төслийн төрөл</div>
                <div className={style.value}>{project.project_type}</div>
              </div>
            </>
          ) : (
            <div></div>
          )}

          <div className={style.details}>
            <div className={style.key}>Нийт шалны талбай</div>
            <div className={style.value}>{project.floor_sqr}</div>
          </div>
          {project.category != "interior" ? (
            <div className={style.details}>
              <div className={style.key}>Барилгын давхар</div>
              <div className={style.value}>{project.floors}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className={style.details}>
            <div className={style.key}>Зураг / Хэрэгжсэн</div>
            <div className={style.value}>
              {project.project_date}/{project.released_date}
            </div>
          </div>
          <div className={style.details}>
            <div className={style.key}>Захиалагч</div>
            <div className={style.value}>{project.client}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
